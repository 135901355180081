import React,{useEffect,useState} from 'react';
import styled from 'styled-components';
import DelCard from '../../components/delcard';
import { useParams, useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import uri from '../../url';

const Content = styled.div`
  background-color:#5f587a;
  .grid{
    display:flex;
    flex-direction:column;
    overflow:hidden;
    padding:0 5vw;
  }
  @media only screen and (min-width: 1024px) {
    .grid{
      display:grid;
      grid-template-columns: 45% 45%;
      gap:55px;
      justify-content:center;
    }
  }
`
const Title = styled.h1`
  font-size:1.5rem;
  text-align:center;
  font-family: 'Poppins', sans-serif;
  color:red;
  margin-top:0px;
  padding-top:20px;
`



const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  color:#243c58;
  background-color:#d6e4ed;
  .pagination{
    display:flex;
    flex-wrap:wrap;
    padding:0;
    justify-content:center;
    li{
      list-style: none;
      margin:20px;
    }
    .disabled{
      color:gray;
    }
    .active{
      color:black;
      text-decoration:underline;
      text-decoration-thickness:2px;
      text-underline-offset:5px;

    }
  }
`;

function DelEntry() {
  const { pageNumber } = useParams(); // Obtenemos el número de página desde la URL
  const navigate = useNavigate(); // Para cambiar la página en la URL
  const [entries, setEntries] = useState([]); // Estado para las entradas
  const [totalPages, setTotalPages] = useState(0); 

  const handlePageChange = (data) => {
    const selectedPage = data.selected + 1; // Los índices empiezan en 0, por lo que sumamos 1
    navigate(`/${selectedPage}`); // Actualizamos la URL con la nueva página
    window.scrollTo(0, 0); // Desplazamos la vista hacia la parte superior de la página
  };
  useEffect(() => {
    const fetchEntriesAndTotalPages = async () => {
      try {
        // Obtener el total de páginas
        const totalPagesResponse = await fetch(uri+'/pages');
        const totalPagesData = await totalPagesResponse.json();
        setTotalPages(totalPagesData.totalPages); // Guardamos el número de páginas
        
        
        // Obtener las entradas para la página actual
        const entriesResponse = await fetch(`${uri}/entries/?page=${pageNumber || 1}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          }
        });
        
        
        const entriesData = await entriesResponse.json();
        setEntries(entriesData.entries); // Guardamos las entradas
      } catch (error) {
        console.error('Error al obtener las entradas o el número de páginas:', error);
      }
    };

    fetchEntriesAndTotalPages();
  
    
  }, [pageNumber])
  
  return (
    <Content>
      <Title>Eliminar entradas</Title>
      <section className='grid'>
        {entries.map((entry) => (
          <DelCard
            key={entry.id}
            id={entry.id}
            h1={entry.title}
            p={entry.description}
            views={entry.views}
            img={entry.cover}
            clock={entry.createdAt}
          />
        ))}
      </section>
      <PaginationContainer>
        <ReactPaginate
            previousLabel={'⬅'}
            nextLabel={'⮕'}
            breakLabel={'...'}
            pageCount={totalPages} // Número total de páginas
            marginPagesDisplayed={2}
            pageRangeDisplayed={10}
            onPageChange={handlePageChange}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />
      </PaginationContainer>
    </Content>
  )
}

export default DelEntry