import React from 'react';
import styles from './card.module.css';
import { Link } from 'react-router-dom';
import { FaEye, FaClock } from 'react-icons/fa';

function Card(props) {
  return (
    <section className={styles.card}>
      <img alt='' src={props.img} className={styles.front} />
      <h1 className={styles.title}>{props.h1}</h1>
      <p className={styles.p}>{props.p}</p>
      <div className={styles.views}>
        <FaEye className={styles.icon} />
        <p>{props.views}</p>
        <FaClock style={{marginLeft:'30px'}} className={styles.icon} />
        <p >{props.clock}</p>
      </div>
      <Link to={'/entries/' + props.id} className={styles.button}>
        Leer completo
      </Link>
    </section>
  );
}

export default Card;
