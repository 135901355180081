import React, { useState } from 'react';
import styles from './card.module.css';
import { FaEye, FaClock } from 'react-icons/fa';
import uri from '../url';
function DelCard(props) {
  const [clickCount, setClickCount] = useState(0);
  const [buttonColor, setButtonColor] = useState('orange');

  const handleDeleteClick = () => {
    if (clickCount === 0) {
      // Cambia el color del botón en el primer clic
      setButtonColor('red');
    } else if (clickCount === 1) {
      // Realiza el fetch en el segundo clic y muestra la alerta
      fetch(`${uri}/api/delete/${props.id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('Success:', data);
          alert('Artículo o Entrada Eliminada');
          window.location.reload(); // Recarga la página
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
    setClickCount(clickCount + 1);
  };

  return (
    <section className={styles.card}>
      <img alt='' src={props.img} className={styles.front} />
      <h1 className={styles.title}>{props.h1}</h1>
      <p className={styles.p}>{props.p}</p>
      <div className={styles.views}>
        <FaEye className={styles.icon} />
        <p>{props.views}</p>
        <FaClock style={{ marginLeft: '30px' }} className={styles.icon} />
        <p>{props.clock}</p>
      </div>
      <button
        onClick={handleDeleteClick}
        style={{
          backgroundColor: buttonColor,
          padding: '10px',
          color: 'white',
        }}
      >
        ELIMINAR
      </button>
    </section>
  );
}

export default DelCard;