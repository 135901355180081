import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Header from '../components/header';
import uri from '../url'; // Asegúrate de que esta constante `uri` esté configurada correctamente.
import Footer from '../components/footer/footer';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Loading from '../components/loading'; // Importa el componente Loading

const Content = styled.div`
  background: linear-gradient(135deg, #243c58, #d6e4ed);
  
  .pc{
      display:none;
    }
  .gallery{
    max-width:100%;
    overflow:hidden;
    padding-bottom:20px;
    img{
      width: 100%;
      max-height:70vh;
      object-fit:contain;
    }
  }
  .slick-dots {
    position:sticky;
  }
  .slick-prev {
    z-index: 100;
    left:75px;
    top:98%;
  }
  .slick-next{
    z-index: 100;
    right:75px;
    top:98%;
  }
  @media only screen and (min-width: 1024px) {
    .pc{
      display:block;
    }
    .mobile{
      display:none;
    }
    .gallery{
      img{
        width:100%;
        margin:auto;
      }
    }
  }

`;

const Banner = styled.div`
  height: 30vh;
  width: 100%;
  background-image: url('/home/bgb.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media (min-width: 1024px) {
    height: 135vh;
  }
`;

const Title = styled.h1`
  font-size: 1.5rem;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  color: white;
  margin-top: 20px;
`;

const CategoryTitle = styled.h2`
  font-size: 1.3rem;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  color: #333;
  margin-top: 20px;
`;

const ImageWrapper = styled.div`
  padding: 10px;
`;

export default function Gallery() {
  // Estado para almacenar los datos de la galería
  const [galleryData, setGalleryData] = useState(null);

  // Configuración para el carrusel
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows:true
  };

  useEffect(() => {
    // Hacemos la solicitud GET a la API
    const fetchData = async () => {
      try {
        const response = await fetch(`${uri}/galleryjson`); // Concatenamos la URI con 'galleryjson'
        if (response.ok) {
          const data = await response.json();
          setGalleryData(data); // Guardamos la respuesta en el estado
          console.log(data); // Imprimimos los datos en la consola
        } else {
          console.error('Error al hacer la solicitud:', response.status);
        }
      } catch (error) {
        console.error('Error al realizar el fetch:', error);
      }
    };

    fetchData();
  }, []); // El array vacío asegura que esto se ejecute solo una vez al montar el componente

  return (
    <Content>
      <Banner></Banner>
      <Header selected={1}></Header>
      <Title>Galería de fotos</Title>

      {galleryData && galleryData.photo_library ? (
        <>
          {Object.keys(galleryData.photo_library).map((category, index) => (
            <div key={index} className='gallery mobile'>
              {/* Contenido para dispositivos móviles */}
              <CategoryTitle>{category}</CategoryTitle>
              {galleryData.photo_library[category].length > 1 ? (
                <Slider {...settings}>
                  {galleryData.photo_library[category].map((image, idx) => (
                    <ImageWrapper key={idx}>
                      <img src={image} alt={`Imagen ${idx}`} />
                    </ImageWrapper>
                  ))}
                </Slider>
              ) : (
                <ImageWrapper>
                  <img
                    className='alone'
                    src={galleryData.photo_library[category][0]}
                    alt={`Imagen 0`}
                    style={{ width: '100%' }}
                  />
                </ImageWrapper>
              )}
            </div>
          ))}

          {Object.keys(galleryData.photo_library).map((category, index) => (
            <div key={index} className='gallery pc'>
              {/* Contenido para PC */}
              <CategoryTitle>{category}</CategoryTitle>
              {galleryData.photo_library[category].length === 1 ? (
                // Si solo hay una imagen, mostrarla sin slider
                <ImageWrapper style={{ display: 'flex' }}>
                  <img
                    style={{ maxHeight: '70vh', maxWidth: '70%', objectFit: 'contain' }}
                    src={galleryData.photo_library[category][0]}
                    alt={`Imagen 0`}
                  />
                </ImageWrapper>
              ) : galleryData.photo_library[category].length === 2 ? (
                // Si hay exactamente dos imágenes, mostrar ambas sin flechas ni puntos
                <Slider
                  {...settings}
                  slidesToShow={2}
                  arrows={false} // Desactivar las flechas
                  dots={false}   // Desactivar los puntitos
                >
                  {galleryData.photo_library[category].map((image, idx) => (
                    <ImageWrapper key={idx}>
                      <img src={image} alt={`Imagen ${idx}`} />
                    </ImageWrapper>
                  ))}
                </Slider>
              ) : (
                // Si hay más de dos imágenes, mostrar el slider con 3 imágenes por vista
                <Slider {...settings} slidesToShow={3}>
                  {galleryData.photo_library[category].map((image, idx) => (
                    <ImageWrapper key={idx}>
                      <img src={image} alt={`Imagen ${idx}`} />
                    </ImageWrapper>
                  ))}
                </Slider>
              )}
            </div>
          ))}
        </>
      ) : (
        // Mostrar el componente Loading mientras se cargan los datos
        <Loading />
      )}

      <Footer></Footer>
    </Content>
  );
}