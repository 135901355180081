import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import uri from '../../url';

const Content = styled.div`
  background: linear-gradient(135deg, #243c58, #d6e4ed);
  padding-top:20px;
  min-height:100vh;
`;


const Title = styled.h1`
  font-size: 1.5rem;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  color: white;
  margin-top: 20px;
`;

const CategoryTitle = styled.h2`
  font-size: 1.2rem;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  color: #333;
  margin-top: 20px;
`;

const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const ImageWrapper = styled.div`
  width: 400px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Image = styled.img`
  width: 100%;
  object-fit: cover;
`;

const DeleteButton = styled.button`
  margin-top: 10px;
  padding: 8px 12px;
  background-color: ${(props) => (props.isClickedOnce ? '#d9534f' : '#f0ad4e')};
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  &:hover {
    background-color: ${(props) => (props.isClickedOnce ? '#c9302c' : '#ec971f')};
  }
`;

export default function DelPhoto() {
  const [galleryData, setGalleryData] = useState(null);
  const [clickedButtons, setClickedButtons] = useState({});

  useEffect(() => {
    // Hacemos la solicitud GET a la API para obtener los datos de la galería
    const fetchData = async () => {
      try {
        const response = await fetch(`${uri}/galleryjson`);
        if (response.ok) {
          const data = await response.json();
          setGalleryData(data);
        } else {
          console.error('Error al hacer la solicitud:', response.status);
        }
      } catch (error) {
        console.error('Error al realizar el fetch:', error);
      }
    };

    fetchData();
  }, []);

  const handleDelete = async (imageSrc) => {
    if (clickedButtons[imageSrc]) {
      // Si el botón ya fue clickeado una vez (está en rojo), procedemos a eliminar
      try {
        const response = await fetch(`${uri}/api/delphoto`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ src: imageSrc }),
        });

        const result = await response.json();

        if (response.ok) {
          alert(result.message || 'Imagen eliminada exitosamente');
        } else {
          alert(result.error || 'Error al eliminar la imagen');
        }

        // Refrescamos la página
        window.location.reload();

      } catch (error) {
        console.error('Error al eliminar la imagen:', error);
        alert('Error al eliminar la imagen');
        // Refrescamos la página
        window.location.reload();
      }
    } else {
      // Si es la primera vez que se clickea el botón, cambiamos su estado a "clickeado una vez"
      setClickedButtons((prevState) => ({
        ...prevState,
        [imageSrc]: true,
      }));
    }
  };

  return (
    <Content>
      <Title>Administrar Galería de Fotos</Title>

      {galleryData && galleryData.photo_library && (
        Object.keys(galleryData.photo_library).map((category, index) => (
          <div key={index}>
            <CategoryTitle>{category}</CategoryTitle>
            <ImagesContainer>
              {galleryData.photo_library[category].map((image, idx) => (
                <ImageWrapper key={idx}>
                  <Image src={image} alt={`Imagen ${idx}`} />
                  <DeleteButton
                    onClick={() => handleDelete(image)}
                    isClickedOnce={clickedButtons[image] || false}
                  >
                    {clickedButtons[image] ? 'Confirmar Eliminación' : 'Eliminar'}
                  </DeleteButton>
                </ImageWrapper>
              ))}
            </ImagesContainer>
          </div>
        ))
      )}

    </Content>
  );
}