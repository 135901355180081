import React from 'react';
import styles from './header.module.css';
import { Link } from 'react-router-dom';
import { FaHome, FaImages } from 'react-icons/fa';

export default function Header({ selected }) {
  return (
    <header className={styles.header}>
      <Link to="/">
        <div className={selected === 0 ? styles.selected : styles.navItem}>
          <FaHome className={styles.icon} />
          <p>Inicio</p>
        </div>
      </Link>
      <Link to="/gallery">
        <div className={selected === 1 ? styles.selected : styles.navItem}>
          <FaImages className={styles.icon} />
          <p>Galería</p>
        </div>
      </Link>
    </header>
  );
}

