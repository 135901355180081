import React, { useState, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Importa los estilos básicos de Quill
import styled from 'styled-components';
import { FaUpload, FaYoutube, FaTextHeight, FaImages, FaFileAlt } from 'react-icons/fa';
import uri from '../../url';
const Font = ReactQuill.Quill.import('formats/font');
Font.whitelist = ['sans-serif', 'serif', 'monospace'];  // Use standard CSS font names
ReactQuill.Quill.register(Font, true);


const modules = {
  toolbar: [
    [{ 'header': '1' }, { 'header': '2' }, { 'font': ['sans-serif', 'serif', 'monospace'] }],
    [{ 'size': [] }],
    [{ 'list': 'bullet' }],
    ['bold', 'italic', 'underline', 'strike', ],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'align': [] }],
    ['link', 'image'],
    ['clean']
  ],
};


const formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike',
  'list', 'bullet', 'indent',
  'link', 'image', 'color', 'background', 'align'
];

// Estiliza el contenedor del formulario
const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 20px;
  max-width: 800px;
  margin: 0px auto;
  background-color: #243c58;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  .quill{
    background-color:#d6e4ed;
    overflow:hidden;
  }
  .ql-tooltip {
    background-color: #f9f9f9;
    border-radius: 5px;
    padding: 5px;
    left: 30px !important;
    top: -10px !important;
    .ql-action {
      color: white;
      background-color: #007bff;
      padding: 5px 5px;
      text-align: center;
      &:after {
        content: "Guardar" !important; /* Cambiar el texto a Guardar */
      }
    }

    .ql-remove {
      margin-left: 10px;
    }
  }

`;

const Title = styled.h1`
  font-size: 1.6rem;
  color: #d6e4ed;
  margin-bottom: 20px;
`;

const Label = styled.h2`
  font-size: 1.4rem;
  color: #d6e4ed;
  margin-bottom: 10px;
  display:flex;
  align-items:center;
  svg{
    margin-right:10px;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  margin-bottom: 20px;
  border: 3px solid #ddd;
  border-radius: 5px;
  background-color:#d6e4ed;
  &:focus {
    outline: none;
    border-color: #4CAF50;
  }
`;

const SubmitButton = styled.button`
  margin-top:10px;
  padding: 10px 20px;
  font-size: 1.2rem;
  color: black;
  background-color: #d6e4ed;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #5f587a;
  }
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;
const HelperText = styled.p`
  font-size: 12px;
  color: #888;
  margin-top: 5px;
`;
function AddEntry() {
  const [value, setValue] = useState('');
  const [title, setTitle] = useState('');
  const [coverPhoto, setCoverPhoto] = useState(null);
  const [description, setDescription] = useState('');
  const [youtubeURLs, setYoutubeURLs] = useState(['']);
  const [isLoading, setIsLoading] = useState(false);
  const quillRef = useRef(null);
  const fileInputRef = useRef(null);

  const handleCoverPhotoChange = (e) => {
    const file = e.target.files[0];
    setCoverPhoto(file);
  };

  const handleYoutubeURLChange = (index, value) => {
    const newYoutubeURLs = [...youtubeURLs];
    newYoutubeURLs[index] = value;
    setYoutubeURLs(newYoutubeURLs);

    if (value !== '' && youtubeURLs.length < 3 && index === youtubeURLs.length - 1) {
      setYoutubeURLs([...youtubeURLs, '']);
    }
  };

  const isFormValid = () => {
    return title.trim() !== '' && description.trim() !== '' && value.trim() !== '';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading) return;
    if (!isFormValid()) {
      alert('Por favor, llena todos los campos requeridos.');
      return;
    }

    setIsLoading(true);

    const quillEditor = quillRef.current.getEditor();
    let content = quillEditor.root.innerHTML;

    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);

    if (coverPhoto) {
      formData.append('coverPhoto', coverPhoto);
    }

    youtubeURLs.forEach((url, index) => {
      if (url) {
        formData.append(`youtubeURL_${index + 1}`, url);
      }
    });

    const imageFiles = quillEditor.root.querySelectorAll('img');
    for (const img of imageFiles) {
      const base64 = img.src;
      const mimeType = base64.match(/data:(image\/[a-zA-Z]+);base64/);

      if (mimeType) {
        const blob = await fetch(base64).then(res => res.blob());
        const fileName = `image_${Date.now()}.${mimeType[1].split('/')[1]}`;
        formData.append('images', blob, fileName);

        content = content.replace(base64, `TEMP_URL_${fileName}`);
      }
    }

    formData.append('content', content);

    try {
      const response = await fetch(uri+'/api/addentry', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        alert(data.message);
        setTitle('');
        setValue('');
        setDescription('');
        setCoverPhoto(null);
        setYoutubeURLs(['']);

        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      } else {
        const errorData = await response.json();
        alert('Error del servidor: ' + errorData.message);
      }
    } catch (error) {
      alert('Hubo un error al enviar la solicitud.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormContainer onSubmit={handleSubmit}>
      <Title>Agregar entrada</Title>
      <Label>
        <FaTextHeight /> Título:
      </Label>
      <HelperText>El título puede tener hasta 200 caracteres.</HelperText>
      <Input 
        type="text" 
        value={title} 
        onChange={(e) => setTitle(e.target.value)} 
        placeholder="Escribe el título aquí..."
        disabled={isLoading}
      />

      <Label>
        <FaUpload /> Foto de portada:
      </Label>
      <HelperText>Se sugiere que la foto pese máximo 5 MB y sea de tipo PNG, JPG o JPEG.</HelperText>
      <Input 
        type="file" 
        accept="image/*"
        onChange={handleCoverPhotoChange}
        disabled={isLoading}
        ref={fileInputRef}
      />

      <Label>
        <FaFileAlt /> Descripción:
      </Label>
      <HelperText>Máximo 300 caracteres.</HelperText>
      <Input 
        type="text" 
        value={description} 
        onChange={(e) => setDescription(e.target.value)} 
        placeholder="Escribe la descripción aquí..."
        disabled={isLoading}
      />

      <Label>
        <FaImages /> Texto y contenido enriquecido:
      </Label>
      <HelperText>Puedes agregar hasta 10 imágenes como máximo.</HelperText>
      <ReactQuill 
        ref={quillRef}
        value={value} 
        onChange={setValue} 
        modules={modules}
        formats={formats}
        placeholder="Escribe tu contenido aquí..."
      />

      <Label>
        <FaYoutube /> Agregar videos de YouTube:
      </Label>
      <HelperText>Presiona cualquier tecla para activar antes de escribir</HelperText>
      {youtubeURLs.map((url, index) => (
        <Input 
          key={index} 
          type="text" 
          value={url} 
          onChange={(e) => handleYoutubeURLChange(index, e.target.value)} 
          placeholder={`URL del video de YouTube ${index + 1}`} 
          disabled={isLoading}
        />
      ))}

      <SubmitButton type="submit"  disabled={isLoading || !isFormValid()}>
        {isLoading ? 'Enviando...' : 'Agregar entrada'}
      </SubmitButton>
    </FormContainer>
  );
}



export default AddEntry;