import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaUpload } from 'react-icons/fa';
import uri from '../../url'; // Importa la URL del backend desde uri.js

const FormWrapper = styled.div`
  padding: 20px;
  border: 2px solid #d6e4ed;
  border-radius: 10px;
  width: 400px;
  margin: 20px auto;
  background-color:#243c58;
  color:#d6e4ed;
  select{
    padding: 10px;
    margin-bottom: 10px;
    width: 100%;
    background-color: #d6e4ed; /* Pantone 6148 C */
    border: 2px solid #5f587a; /* Pantone 4125 C */
    color: #243c58; /* Texto en Pantone 534 C */
    border-radius: 8px;
    font-size: 1rem; /* Usa rem para accesibilidad */
    appearance: none; /* Elimina el estilo por defecto del navegador */
    background-image: url("data:image/svg+xml;charset=US-ASCII,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='5' viewBox='0 0 10 5'><path fill='#5f6c96' d='M0 0l5 5 5-5z'/></svg>");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 12px;
    cursor: pointer;

    &:focus {
      outline: none;
      border-color: #5f6c96; /* Pantone 2109 C */
      box-shadow: 0 0 5px #5f6c96; /* Sombra al hacer focus */
  }

  option {
    background-color: #d6e4ed; /* Pantone 6148 C */
    color: #243c58; /* Pantone 534 C */
  }
  }
`;

const SectionWrapper = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  font-size: 1.2rem;
  margin-bottom: 5px;
`;

const Input = styled.input`
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
  border: 2px solid #5f587a; /* Pantone 4125 C */
  background-color: #d6e4ed; /* Pantone 6148 C */
  color: #243c58; /* Texto en Pantone 534 C */
  border-radius: 8px;
  font-size: 1rem;  /* Usando rem en lugar de px */

  &:focus {
    outline: none;
    border-color: #5f6c96; /* Pantone 2109 C */
    box-shadow: 0 0 5px #5f6c96; /* Sombra al hacer focus */
  }

  &::placeholder {
    color: #a4bac7; /* Pantone 5435 C para el placeholder */
  }
`;

const Button = styled.button`
  padding: 10px 20px; /* Ajusta el padding para que el botón sea más amplio */
  background-color: ${props => (props.disabled ? '#a4bac7' : '#5f587a')}; /* Pantone 5435 C para disabled */
  color: white;
  border: none;
  border-radius: 8px; /* Bordes más suaves */
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  font-size: 1rem; /* Tamaño accesible */
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${props => (props.disabled ? '#a4bac7' : '#d6148c')}; /* Pantone 6148 C para hover */
  }

  &:active {
    background-color: ${props => (props.disabled ? '#a4bac7' : '#5f6c96')}; /* Pantone 2109 C para active */
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 5px ${props => (props.disabled ? '#a4bac7' : '#d6148c')}; /* Sombra para focus */
  }
`;


const ErrorMsg = styled.p`
  color: red;
`;

const Addphoto = () => {
  const [photoLibrary, setPhotoLibrary] = useState({});
  const [sectionName, setSectionName] = useState('');
  const [selectedSection, setSelectedSection] = useState('');
  const [photos, setPhotos] = useState([]);
  const [isCreatingNewSection, setIsCreatingNewSection] = useState(false);
  const [error, setError] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);



  // Fetch data from the backend to populate the photoLibrary
  useEffect(() => {
    const fetchGallery = async () => {
      try {
        const response = await fetch(`${uri}/galleryjson`); // Utiliza la ruta del backend para hacer fetch
        if (!response.ok) {
          throw new Error('Failed to fetch gallery');
        }
        const data = await response.json();
        setPhotoLibrary(data.photo_library); // Actualiza el estado con la respuesta del servidor
      } catch (err) {
        console.error(err);
        setError('Error fetching gallery data');
      }
    };

    fetchGallery();
  }, []);

  // Validación del formulario
  useEffect(() => {
    if (photos.length === 0) {
      setIsFormValid(false);
      return;
    }

    if (isCreatingNewSection) {
      if (sectionName.trim() === '') {
        setIsFormValid(false);
        return;
      }
    } else {
      if (selectedSection === '') {
        setIsFormValid(false);
        return;
      }
    }

    setIsFormValid(true);
  }, [photos, sectionName, selectedSection, isCreatingNewSection]);
  
  const fetchGallery = async () => {
    try {
      const response = await fetch(`${uri}/galleryjson`);
      if (!response.ok) {
        throw new Error('Failed to fetch gallery');
      }
      const data = await response.json();
      setPhotoLibrary(data.photo_library);
    } catch (err) {
      console.error(err);
      setError('Error fetching gallery data');
    }
  };
  const handleToggleSection = (e) => {
    setIsCreatingNewSection(e.target.value === 'new');
    setSectionName('');
    setSelectedSection('');
    setError('');
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 5) {
      setError('Puedes subir hasta 5 imágenes');
    } else {
      setPhotos(files);
      setError('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid) {
        setError('Por favor, completa todos los campos requeridos.');
        return;
    }

    const formData = new FormData();
    photos.forEach((photo) => {
        formData.append('photos', photo);
    });

    if (isCreatingNewSection) {
        formData.append('sectionName', sectionName);
    } else {
        formData.append('sectionName', selectedSection);
    }

    try {
        const response = await fetch(`${uri}/api/addphoto`, {
            method: 'POST',
            body: formData,
        });

        const data = await response.json();

        if (!response.ok) {
            throw new Error(data.error || 'Error al subir las fotos');
        }

        // Notificación de éxito
        window.alert('Fotos subidas exitosamente');
        await fetchGallery(); // Refresca la lista de secciones después de crear una nueva
        // Resetear el formulario
        setSectionName('');
        setSelectedSection('');
        setPhotos([]);
        setError('');
    } catch (err) {
        console.error(err);
        setError('Error al subir las fotos');
        // Notificación de error
        window.alert('Error al subir las fotos');
    }
  };


  return (
    <FormWrapper>
      <form onSubmit={handleSubmit}>
        <SectionWrapper>
          <Label>Elegir opción de sección</Label>
          <select onChange={handleToggleSection}>
            <option value="existing">Añadir a sección existente</option>
            <option value="new">Crear nueva sección</option>
          </select>
        </SectionWrapper>

        {isCreatingNewSection ? (
          <SectionWrapper>
            <Label>Nombre de la nueva sección</Label>
            <Input
              type="text"
              value={sectionName}
              onChange={(e) => setSectionName(e.target.value)}
              placeholder="Ingresa el nombre de la sección"
            />
          </SectionWrapper>
        ) : (
          <SectionWrapper>
            <Label>Seleccionar sección existente</Label>
            <select
              onChange={(e) => setSelectedSection(e.target.value)}
              value={selectedSection}
            >
              <option value="">Selecciona una sección</option>
              {Object.keys(photoLibrary).map((section) => (
                <option key={section} value={section}>
                  {section}
                </option>
              ))}
            </select>
          </SectionWrapper>
        )}

        <SectionWrapper>
          <Label>Subir imágenes (Máximo 5)</Label>
          <Input
            type="file"
            multiple
            onChange={handleFileChange}
            accept="image/*"
          />
        </SectionWrapper>

        {error && <ErrorMsg>{error}</ErrorMsg>}

        <Button type="submit" disabled={!isFormValid}>
          <FaUpload style={{ marginRight: '8px' }} /> Subir fotos
        </Button>
      </form>
    </FormWrapper>
  );
};

export default Addphoto;