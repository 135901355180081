import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaPhotoVideo, FaTrashAlt, FaPlusSquare, FaMinusSquare } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import uri from '../../url';

const MenuContainer = styled.div`
  width: 350px;
  background-color: #243c58;
  margin:auto;
  color: #d6e4ed;
  padding: 20px;
  margin-top:20px;
  border-radius: 10px;
  font-family: 'Poppins', sans-serif;
`;

const NavList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const NavItem = styled.li`
  margin-bottom: 15px;
  a {
    color: #d6e4ed;
    text-decoration: none;
    display: flex;
    align-items: center;
    &:hover {
      color: #ec971f;
    }
  }
  svg {
    margin-right: 8px;
  }
`;

const UsageBarContainer = styled.div`
  margin-top: 30px;
`;

const UsageBar = styled.div`
  width: 100%;
  height: 20px;
  background-color: #d6e4ed;
  border-radius: 10px;
  overflow: hidden;
`;

const UsageBarFill = styled.div`
  height: 100%;
  width: ${props => props.percentage}%;
  background-color: red;
`;

const UsageText = styled.p`
  margin-top: 10px;
  font-size: 0.9rem;
  text-align: center;
`;

const Menu = () => {
  const [usagePercentage, setUsagePercentage] = useState(0);
  const [usedGB, setUsedGB] = useState(0);
  const maxGB = 2.5; // Capacidad máxima en GB

  useEffect(() => {
    const fetchUsage = async () => {
      try {
        const response = await fetch(`${uri}/disk`);
        if (!response.ok) {
          throw new Error('Error al obtener el uso de disco');
        }
  
        const data = await response.json();
        const percentage = data.disk;  // El servidor ya te da un porcentaje en decimal, lo multiplicamos por 100
        const usedGB = ((data.disk * maxGB)/100).toFixed(2);  // Calculamos cuántos GB se han usado según el porcentaje
  
        setUsagePercentage(percentage.toFixed(2));  // Fijamos el porcentaje
        setUsedGB(usedGB);  // Fijamos el valor en GB usados
      } catch (error) {
        console.error('Error al obtener el uso de disco:', error);
      }
    };
  
    fetchUsage();
  }, []);

  return (
    <MenuContainer>
      <NavList>
        <NavItem>
          <Link to="/api/addphoto">
            <FaPhotoVideo /> Agregar Foto
          </Link>
        </NavItem>
        <NavItem>
          <Link to="/api/delphoto">
            <FaTrashAlt /> Eliminar Foto
          </Link>
        </NavItem>
        <NavItem>
          <Link to="/api/entrada">
            <FaPlusSquare /> Agregar Entrada
          </Link>
        </NavItem>
        <NavItem>
          <Link to="/api/eliminar">
            <FaMinusSquare /> Eliminar Entrada
          </Link>
        </NavItem>
      </NavList>

      <UsageBarContainer>
        <UsageBar>
          <UsageBarFill percentage={usagePercentage} />
        </UsageBar>
        <UsageText>
          Uso de disco: {usedGB} GB de {maxGB} GB ({usagePercentage}%)
        </UsageText>
      </UsageBarContainer>
    </MenuContainer>
  );
};

export default Menu;