import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FaSpinner } from 'react-icons/fa'; // Importa el ícono de react-icons

// Define la animación de rotación
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

// Estiliza el ícono utilizando styled-components
const SpinnerIcon = styled(FaSpinner)`
  font-size: 50px;
  color: #8899AA; // Puedes cambiar el color si lo prefieres
  animation: ${spin} 1s linear infinite;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; // Ajusta la altura para centrar verticalmente
`;

// Componente Loading
const Loading = () => {
  return (
    <LoadingContainer>
      <SpinnerIcon />
    </LoadingContainer>
  );
};

export default Loading;
