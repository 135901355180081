import React from 'react';
import { BrowserRouter as Router, Routes, Route,Navigate  } from 'react-router-dom';
import AddEntry from './pages/admin/addEntry';
import Home from './pages/home/home';
import Entries from './pages/entries';
import DelEntry from './pages/admin/delEntry';
import Gallery from './pages/gallery';
import Addphoto from './pages/admin/addPhoto';
import DelPhoto from './pages/admin/delPhoto';
import Menu from './pages/admin/menu';
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/1" />} />
        <Route path="/:pageNumber" element={<Home />} />
        <Route path="/entries" element={<Navigate to="/entries/1" />} />
        <Route path="/gallery" element={<Gallery/>} />
        <Route path="/entries/:id" element={< Entries/>} />
        <Route path="/api" element={<Menu />} />
        <Route path="/api/addphoto" element={<Addphoto />} />
        <Route path="/api/delphoto" element={<DelPhoto />} />
        <Route path="/api/entrada" element={<AddEntry />} />
        <Route path="/api/eliminar" element={<DelEntry />} />
      </Routes>
    </Router>
  );
}

export default App;
