import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import uri from '../url';
import styled from 'styled-components';
import { AiOutlineEye, AiOutlineClockCircle } from 'react-icons/ai';
import Footer from '../components/footer/footer';
import Loading from '../components/loading';
// Definir los componentes estilizados
const EntryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
  padding: 15px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  align-items: start;
  overflow: hidden;
`;

const Title = styled.h1`
  text-align: center;
  font-size: 2.5rem;
  color: #333;
  align-self: center;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  width: 100%;
`;

const Description = styled.p`
  font-size: 1.2rem;
  color: #666;
  margin: 20px 0;
  text-align: justify;
  border-bottom: 1px solid black;
  padding-bottom: 40px;
  width: 100%;
`;

const CoverImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 20px;
`;

const Content = styled.div`
  font-size: 1.1rem;
  line-height: 1.6;
  width: 100%;
  
  .ql-align-right {
    text-align: end;
  }

  img {
    max-width: 100%;
    max-height: 40vh;
    object-fit: cover;
  }

  .ql-font-sans-serif {
    font-family: sans-serif;
  }

  .ql-font-serif {
    font-family: serif;
  }

  .ql-font-monospace {
    font-family: monospace;
  }

  .ql-size-small {
    font-size: 0.8rem;
  }

  .ql-size-large {
    font-size: 1.5rem;
  }

  .ql-size-huge {
    font-size: 2rem;
  }
  .ql-align-center{
    text-align:center;
  }
  .ql-align-justify {
    text-align: justify;
  }

`;

// Estilo para centrar los videos de YouTube
const YoutubeURLs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
  width:100%;
  iframe {
    width: 100%;
    max-width: 560px; // Ancho máximo del video
    height: 315px;
    margin-bottom: 20px;
  }
`;
const Data = styled.div`
  border-top: 1px solid black;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #243c58;

  .icon {
    width: 30px;
    height: 30px;
    margin: 10px;
  }

  .H {
    width: 30px;
    height: 30px;
    margin-left: 25px;
    margin-right:10px;
  }
`;

// Componente principal
export default function Entries() {
  const { id } = useParams(); // Recuperamos el parámetro id de la URL
  const [entry, setEntry] = useState(null);

  useEffect(() => {
    const fetchEntry = async () => {
      try {
        const response = await fetch(`${uri}/entry?id=${id || 0}`);
        const data = await response.json();
        setEntry(data.entry);
      } catch (error) {
        console.error('Error al obtener la entrada:', error);
      }
    };

    fetchEntry();
  }, [id]);

  // Verifica si los datos de la entrada están disponibles
  if (!entry) return <Loading>Cargando...</Loading>;

  // Extraer URLs de YouTube
  const youtubeURLs = [entry.youtubeURL_1, entry.youtubeURL_2, entry.youtubeURL_3].filter(url => url);

  return (
    <>
      <EntryWrapper>
        <CoverImage src={entry.cover} alt={entry.title} />
        <Title>{entry.title}</Title>
        <Description>{entry.description}</Description>
        <Content dangerouslySetInnerHTML={{ __html: entry.content }} />
        <YoutubeURLs>
          {youtubeURLs.length > 0 &&
            youtubeURLs.map((url, index) => (
              <iframe
                key={index}
                src={`https://www.youtube.com/embed/${new URL(url).searchParams.get('v')}`}
                title={`YouTube video player ${index + 1}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            ))}
        </YoutubeURLs>
        <Data>
          <AiOutlineEye className='icon' />
          <p>{entry.views}</p>
          <AiOutlineClockCircle className='H' />
          <p>{entry.createdAt}</p>
        </Data>
          
      </EntryWrapper>
      <Footer></Footer>
    </>
    
    
  );
}